<template>
    <div class="form">
        <div class="background background_blue background_broker">
            <div class="background__image background__image_broker"></div>
        </div>

        <div class="wrapper">
            <div class="content">
                <div class="content__container brokers">
                    <div class="brokers__title">{{ 'brokers_title' | localize }}</div>
                    <div class="brokers__subtitle">{{ 'brokers_subtitle' | localize }}</div>
                    <div class="brokers__body">
                        <TextBlock class="brokers__text brokers__text_min" :id="24" />
                        <div class="brokers__subtitle2">{{ 'brokers_subtitle_2' | localize }}</div>
                        <div class="brokers__items">
                            <div :class="'brokers__item brokers__item_' + item" v-for="item in 6" :key="item">
                                <div class="brokers__item_num">{{ item }}</div>
                                <div class="brokers__item_text">{{ "b_item_" + item | localize }}</div>
                            </div>
                        </div>
                        <TextBlock class="brokers__text" :id="25" />
                        <router-link class="button button__form" :to="{name: 'Brokers Questionnaire'}">{{ "br_anket" | localize }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextBlock from '@/components/Text.vue'

export default {
  name: 'Broker',
  components: { TextBlock },
}
</script>

<style scoped>
    .brokers__items .brokers__item.brokers__item_1::after { background-image: url("../../assets/images/common/broker/broker_icon_1.png"); }
    .brokers__items .brokers__item.brokers__item_2::after { background-image: url("../../assets/images/common/broker/broker_icon_2.png"); }
    .brokers__items .brokers__item.brokers__item_3::after { background-image: url("../../assets/images/common/broker/broker_icon_3.png"); }
    .brokers__items .brokers__item.brokers__item_4::after { background-image: url("../../assets/images/common/broker/broker_icon_4.png"); }
    .brokers__items .brokers__item.brokers__item_5::after { background-image: url("../../assets/images/common/broker/broker_icon_5.png"); }
    .brokers__items .brokers__item.brokers__item_6::after { background-image: url("../../assets/images/common/broker/broker_icon_6.png"); }

    #app.light-theme .brokers__items .brokers__item.brokers__item_1::after { background-image: url("../../assets/images/common/broker/broker_icon_light_1.png"); }
    #app.light-theme .brokers__items .brokers__item.brokers__item_2::after { background-image: url("../../assets/images/common/broker/broker_icon_light_2.png"); }
    #app.light-theme .brokers__items .brokers__item.brokers__item_3::after { background-image: url("../../assets/images/common/broker/broker_icon_light_3.png"); }
    #app.light-theme .brokers__items .brokers__item.brokers__item_4::after { background-image: url("../../assets/images/common/broker/broker_icon_light_4.png"); }
    #app.light-theme .brokers__items .brokers__item.brokers__item_5::after { background-image: url("../../assets/images/common/broker/broker_icon_light_5.png"); }
    #app.light-theme .brokers__items .brokers__item.brokers__item_6::after { background-image: url("../../assets/images/common/broker/broker_icon_light_6.png"); }
</style>